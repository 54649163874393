<template>
  <v-container class="mx-auto">
    <h2>Agregar operador</h2>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" md>
          <v-text-field v-model="nombre" label="Nombre" outlined dense id="operadorNameInput" />
          <h5 class="text-center">
            El id de este operador será generado automaticamente, e inicialmente
            partirá con un rating de 5 estrellas. A medida que reciba
            valoraciones su valor cambiará.
          </h5>
        </v-col>
      </v-row>
    </v-container>
    <v-btn @click="agregarOperador" :color="colors.mainPurple"  rounded dark width="100%" class="mt-8" id="operadorBtn" 
      >Agregar operador</v-btn
    >
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import utils from "@/helpers/utils";

export default {
  name: "AgregarVuelo",
  data() {
    return {
      nombre: "",
    };
  },
  methods: {
    ...mapActions("Operadores", ["addOperador"]),
    agregarOperador() {
      let operador = {
        nombre: this.nombre,
        rating: 5,
        calificaciones: [],
      };

      this.addOperador(operador);
      alert("Operador (" + operador.nombre + ") agregado");
      this.nombre = "";
    },
  },
  computed: {
    ...mapState(['colors']),
    
  },
  title() {
    return `Agregar operador - HummingBird`;
  }
};
</script>

<style>
</style>